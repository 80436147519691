require('./bootstrap');

/**
 * Swiper JS.
 */

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

// core version + navigation, pagination modules:
import SwiperCore, { Autoplay } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Autoplay]);

const swiper = new Swiper('.members-carousel', {
    lazy: true,
    loop: true,
    slidesPerView: 4,
    slidesPerColumn: 1,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false
    },
    breakpoints: {
        992: {
            slidesPerView: 8
        }
    }
});
